import { t } from "i18next";
import React from "react";
import { currencyFormat } from "../../utils/CurrencyFormat";
import CartSummary from "./CartSummary";

interface CartTableProps {
  cart: any[];
  prods: any[];
  selectedPrices: { [id: string]: number };
  handleQuantityChange: (id: number, action: string, price: number) => void;
  selectVar: (e: any, prodId: number) => void;
}

const CartTable: React.FC<CartTableProps> = ({
  cart,
  prods,
  selectedPrices,
  handleQuantityChange,
  selectVar,
}) => {
  return (
        <div className="col">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col" className="h5">
                {t("Cart.title")}
              </th>
              <th>Product variants</th>
              <th scope="col">{t("Cart.category")}</th>
              <th scope="col">{t("Cart.quantity")}</th>
              <th scope="col">{t("Cart.price")}</th>
            </tr>
          </thead>
          <tbody>
{cart.map((product: any, index: React.Key | null | undefined) => {
// Find the product in the prods list
let prod = prods.find((prod: any) => prod.id == product.id);
let prodCat = "";

// Fallback for undefined prod
if (!prod) {
return (
<tr key={index}>
  <td colSpan={5}>Product data is unavailable.</td>
</tr>
);
}

// Get product category
prod?.categorie.forEach((c: any) => {
if (c.subCategory.length === 0) {
prodCat = c.title;
}
});

// Get variants
let vars: {
id: number;
label: string;
price: number;
}[] = [];
prod?.variantProducts.forEach((v: any) => {
vars.push({
id: v.id,
label: v.variant.label,
price: v.price,
});
});

// Render table row
return (
<tr key={index}>
<td scope="row">
  <div
    className="d-flex  flex-column flex-md-row text-md-start"
    style={{ width: "100%" }}
  >
    <img
      src={`https://api.faragroupe.fr/uploads/product_images/${prod.picture}`}
      className="img-fluid rounded mr-3"
      style={{ width: "120px" }}
      alt={prod.title}
    />
    <div className="d-flex flex-column ms-0 ms-md-4 mt-3 mt-md-0 align-items-start">
      <p className="mb-2">{prod.title}</p>
    </div>
  </div>
</td>
<td className="align-middle">
  {vars.length !== 0 ? (
    <select onChange={(e) => selectVar(e, prod.id)}>
      {vars.map((v: any) => (
        <option key={v.id} value={v.price}>
          {v.label}
        </option>
      ))}
    </select>
  ) : (
    "100g"
  )}
</td>
<td className="align-middle">
  <p className="mb-0" style={{ fontWeight: 500 }}>
    {prodCat}
  </p>
</td>
<td className="align-middle">
  <div className="d-flex flex-row">
    <button
      className="btn btn-outline-light text-dark px-2"
      onClick={() =>
        handleQuantityChange(product.id, "stepDown", selectedPrices[prod.id])
      }
    >
      <i className="fas fa-minus"></i>
    </button>

    <input
      name="quantity"
      value={product.quantity}
      type="text"
      className="form-control form-control-sm"
      style={{ width: "45px" }}
      readOnly
    />

    <button
      className="btn btn-outline-light text-dark px-2"
      onClick={() =>
        handleQuantityChange(product.id, "stepUp", selectedPrices[prod.id])
      }
    >
      <i className="fas fa-plus"></i>
    </button>
  </div>
</td>
<td className="align-middle">
  <p className="mb-0" style={{ fontWeight: 500 }}>
  {cart
    .filter((item: any) => item.id === prod.id)
    .map((item: any) => {
      const price = selectedPrices[item.id] || item.vars.price || prod.price;
      return price * item.quantity;
    })}
  {" "}DH
  </p>
</td>
</tr>
);
})}
</tbody>

        </table>
      </div>
    
    </div>
  );
};

export default CartTable;
