import React from "react";
import { currencyFormat } from "../../utils/CurrencyFormat";
import { t } from "i18next";

interface CartSummaryProps {
  total: number;
  validateOrder: () => void;
  shippingCost: number;
}

const CartSummary: React.FC<CartSummaryProps> = ({
  total,
  validateOrder,
  shippingCost,
}) => {
  const totalWithShipping = total + shippingCost;

  return (
    <div
    className="col-lg-4 col-xl-3 card shadow-2-strong mb-5 mb-lg-0 p-3 "
    style={{ height: "300px" }}
  >
    <div className="d-flex text-dark justify-content-between">
      <h3 className="mb-2">{t("Cart.sum")}</h3>
    </div>
    <hr className="my-2 " />
    <div
      className="d-flex justify-content-between"
      style={{ fontWeight: 500 }}
    >
      <h6 className="mb-2">{t("Cart.total")}</h6>
      <p className="mb-2">{currencyFormat(total)}</p>
    </div>

    <div
      className="d-flex justify-content-between"
      style={{ fontWeight: 500 }}
    >
      <h6 className="mb-0">{t("Cart.shipping")}</h6>
      <p className="mb-0">{currencyFormat(20)}</p>
    </div>

    <hr className="my-4" />

    <div
      className="d-flex justify-content-between mb-4"
      style={{ fontWeight: 500 }}
    >
      <h6 className="mb-2">{t("Cart.taxe")}</h6>
      <p className="mb-2">{currencyFormat(total + 20)}</p>
    </div>

    <button
      onClick={validateOrder}
      type="button"
      data-mdb-button-init
      data-mdb-ripple-init
      className="btn btn-primary btn-block btn-lg mt-auto"
    >
      <div className="d-flex justify-content-between">
        <span>Validation</span>
        <span>{currencyFormat(total + 20)}</span>
      </div>
    </button>
  </div>



  );
};

export default CartSummary;
