import React, { createContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Prods from "./Pages/Products/Prods";
import ShoppingCart from "./Pages/Cart/cart";
import AboutPage from "./Pages/About/Apropos";
import Details from "./Pages/Details/Details";
import Profile from "./Pages/Profile";
import Header from "./Components/Layout/Header";
import axios from "axios";
import { apiUrl } from "./process";
import Wishlist from "./Pages/wishlist";
import { useTranslation } from "react-i18next";
import Loader from "./Components/Shared/Loader";

export const GlobalContext = createContext<any>(null);

const App: React.FC = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [cart, setCart] = useState([]);
  const [sub, setSub] = useState<any[]>([]);
  const [categoriesList, setCategoriesList] = useState<any[]>([]);
  const [pr, setProds] = useState<any[]>([]);
  const [slider, setSlider] = useState<any[]>([]);
  const [sliderCat, setSliderCat] = useState<any[]>([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${apiUrl}/categories.json`);
      const categories = response.data;

      const filteredCategories = categories.filter(
        (e: { parent: any }) => e.parent == null
      );
      const subCategories = categories.filter(
        (e: { parent: any }) => e.parent != null
      );

      const titles = filteredCategories.map(
        (element: { id: string; title: string; picture: string }) => ({
          id: element.id,
          title: element.title,
          picture: element.picture,
        })
      );

      setSub(subCategories);
      setCategoriesList(titles);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchProds = async () => {
    try {
      const response = await axios.get(
        `https://api.faragroupe.fr/api/products.json`
      );
      setProds(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setLoading(false);
    }
  };
  const [favorites, setFavorites] = useState<
    {
      id: number;
      user: { id: number };
      product: {
        id: number;
        picture: string;
        title: string;
        price: number;
        disponibilite: string;
      };
    }[]
  >([]);
  const fetchFavs = async (idU: string) => {
    try {
      const response = await axios.get(
        `https://api.faragroupe.fr/api/favorites.json?user.id=${idU}`
      );
      setFavorites(response.data);
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };

  const fetchSliders = async () => {
    try {
      const response = await axios.get(`${apiUrl}/sliders.json`);
      const sliderHome = [];
      const sliderCategory = [];
      sliderHome.push(...response.data.filter((item: any) => !item.category));
      sliderCategory.push(
        ...response.data.filter((item: any) => item.category)
      );
      setSlider(sliderHome);
      setSliderCat(sliderCategory);
    } catch (error) {
      console.error("Error fetching sliders:", error);
    }
  };
  useEffect(() => {
    fetchCategories();
    fetchProds();
    fetchFavs("");
    fetchSliders();

    const toggleVisibility = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <GlobalContext.Provider
      value={{ pr, cart, setCart, loading, fetchFavs, favorites, setFavorites }}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div
            className="position-fixed flex-column justify-content-start align-items-center slide-top"
            id="inputS"
          >
            <input
              type="search"
              className="form-control mb-3"
              placeholder="Recherche de produits..."
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "16px",
              }}
            />
            <div className="results-container">
              {/* Results will be dynamically inserted here */}
              <p className="text-muted text-center">
                <img src="noProduct.png" />
              </p>
            </div>
          </div>
          <Header categoriesList={categoriesList} sub={sub} />
          <Routes>
            <Route path="/" element={<Home slider={slider} />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/produits"
              element={
                <Prods
                  prods={pr}
                  categoriesList={categoriesList}
                  sub={sub}
                  sliderCat={sliderCat}
                />
              }
            />
            <Route path="/cart" element={<ShoppingCart />} />
            <Route path="/apropos" element={<AboutPage />} />
            <Route path="/details/:id" element={<Details />} />
            <Route
              path="/produits/:cat"
              element={
                <Prods
                  categoriesList={categoriesList}
                  sub={sub}
                  prods={pr}
                  sliderCat={sliderCat}
                />
              }
            />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/like/:id" element={<Wishlist />} />
          </Routes>
          <button
            onClick={handleScrollToTop}
            className="btn btn-light back-to-top"
            style={{ display: isVisible ? "block" : "none" }}
          >
            <i className="fa-solid fa-up-long"></i>
          </button>
        </>
      )}
    </GlobalContext.Provider>
  );
};

export default App;
