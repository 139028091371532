import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { t, use } from "i18next";
import { GlobalContext } from "../App";

import DynamicNav from "../Components/Layout/dynamicNav";
import axios from "axios";

import { toast, ToastContainer } from "react-toastify";

const Wishlist: React.FC = () => {


  const favorites = useContext(GlobalContext).favorites
  const setFavorites = useContext(GlobalContext).setFavorites
  const fetchFavs = useContext(GlobalContext).fetchFavs


  const { idU } = useParams();

  // const fetchFavs = async (idU: string) => {
  //   try {
  //     const response = await axios.get(
  //       `https://api.faragroupe.fr/api/favorites.json?user.id=${idU}`
  //     );
  //     setFavorites(response.data);
  //   } catch (error) {
  //     console.error("Error fetching categories:", error);
  //   }
  // };
  useEffect(() => {
    fetchFavs(idU? idU:"");
  }, [idU]);

  const delFav = async (e: any, id: number) => {
    if(window.confirm(t("toast.confirmDelete"))){
try {
      const idFav = id.toString();
      const response = await axios.delete(
        `https://api.faragroupe.fr/api/favorites/${idFav}`
      );
  
      if (response.status === 204) {
        fetchFavs(idU?idU:"");
        toast.success(t("toast.remove"),{
          autoClose:1800,
          pauseOnHover: false
        });
      } else {
        toast.error("Error while removing the favorite");
      }
    } catch (error) {
      console.log("Error removing favorite:", error);
      toast.error("Error removing favorite");
    }
    }else{
      return;
    }
    
  };

  return (
    <>
      <DynamicNav />

      <div className="wishlist-page container mt-5">
        <div className="text-center">
          <span className="h1 text-black">
            <i className="fa-regular fa-heart"></i>
          </span>
          <h1 className="page-title mb-4 text-dark">{t("wishlist.title")}</h1>
        </div>
<ToastContainer position="top-right" />
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <th scope="col" className="h5">
                  Produit
                </th>
                <th scope="col">Prix</th>
                <th scope="col">Stock status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {favorites.map(
                (fav: {
                  id: number;
                  product: {
                    id: number;
                    picture: string;
                    title: string;
                    price: number;
                    disponibilite: string;
                  };
                }) => (
                  <tr key={fav.id}>
                    <th scope="row">
                      <div
                        className="d-flex  flex-column flex-md-row text-md-start"
                        style={{ width: "100%" }}
                      >
                        <button
                          style={{
                            outline: "none",
                            boxShadow: "none",
                          }}
                          className="btn btn-lg  "
                          onClick={(e)=>delFav(e,fav.id)}
                        >
                          <i className="fa fa-trash"></i>{" "}
                        </button>
                        <img
                          src={`https://api.faragroupe.fr/uploads/product_images/${fav.product.picture}`}
                          className="img-fluid rounded mr-3"
                          style={{ width: "90px", height: "80px" }}
                          alt={fav.product.title}
                        />
                        <div className="d-flex flex-column ms-0 ms-md-4 mt-3 mt-md-0 align-items-start">
                          <p className="mb-2">{fav.product.title}</p>
                        </div>
                      </div>
                    </th>

                    <td>
                      <p className="mb-0">{fav.product.price}dh</p>
                    </td>
                    <td>
                      <span
                        className={`badge ${fav.product.disponibilite === "enstock" ? "badge-success" : "badge-danger"}`}
                      >
                        {fav.product.disponibilite}
                      </span>
                    </td>
                    <td>
                      <span>{t("wishlist.created")}</span>20/10/2024<br></br>
                      <Link to={`/details/${fav.product.id}`}>
                        <button className="btn btn-sm btn-dark">
                          {t("wishlist.add")}
                        </button>
                      </Link>
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Wishlist;


