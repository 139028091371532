import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DynamicNav from "../../Components/Layout/dynamicNav";
import Footer from "../../Components/Layout/Footer";
import { GlobalContext } from "../../App";
import axios from "axios";

import { currencyFormat } from "../../utils/CurrencyFormat";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { doc } from "prettier";
import { useFormik } from "formik";
import CartTable from "./CartTable";
import CartSummary from "./CartSummary";
import ShippingInfo from "./ShippingInfo";
import { title } from "process";
// import { apiImg } from "../process";
// import { ScrollToTop } from "./Scroll";

function ShoppingCart() {
  const { pr, cart, setCart, loading } = useContext(GlobalContext);

  const [selectedPrices, setSelectedPrices] = useState<{
    [id: string]: number;
  }>({});

  const navigate = useNavigate();
  const prods = pr;

  useEffect(() => {
    const savedCart = localStorage.getItem("user-cart");
    if (savedCart) {
      setCart(JSON.parse(savedCart));
    }
  }, [setCart]);

  useEffect(() => {
    localStorage.setItem("user-cart", JSON.stringify(cart));
  }, [cart]);

  const { t } = useTranslation();

  const [total, setTotal] = useState(0);

  function handleQuantityChange(id: number, action: string, price: number) {
    setCart((prevCart: any[]) => {
      return prevCart.reduce(
        (
          newCart: any,
          item: { id: number; quantity: number; price: number }
        ) => {
          if (item.id === id) {
            if (action === "stepUp") {
              setTotal(price * (item.quantity + 1));
              return [...newCart, { ...item, quantity: item.quantity + 1 }];
            } else if (action === "stepDown") {
              if (item.quantity > 1) {
                setTotal(price * (item.quantity - 1));
                return [...newCart, { ...item, quantity: item.quantity - 1 }];
              } else {
                const confirmRemove = window.confirm(t("Cart.alert"));
                if (confirmRemove) {
                  return newCart;
                }
              }
            }
          }
          setTotal(item.price * item.quantity);
          return [...newCart, item];
        },
        []
      );
    });
  }

  useEffect(() => {
    const newTotal = cart.reduce(
      (acc: number, cartItem: { id: any; quantity: number; vars: any }) => {
        let v = cartItem.vars;
        let finalPrice = 0;
        if (v.length > 0) {
          const price = v.map((item: any) => item.price);
          finalPrice = price[0];
        } else {
          const product = prods.find((p: { id: any }) => p.id === cartItem.id);
          const price = product ? product.price : 0;
          finalPrice = price;
        }
        return acc + finalPrice * cartItem.quantity;
      },
      0
    );

    setTotal(newTotal);
  }, [cart]);

  // const [selectedOption, setSelectedOption] = useState("");

  // const handleOptionChange = (value: string) => {
  //   const elements = {
  //     credit: document.getElementById("credit"),
  //     debit: document.getElementById("debit"),
  //     paypal: document.getElementById("paypal"),
  //   };

  //   setSelectedOption(value);

  //   Object.entries(elements).forEach(([key, element]) => {
  //     if (element) {
  //       element.style.display = key === value ? "block" : "none";
  //     }
  //   });
  // };

  const storedUserInfo = localStorage.getItem("user-info");
  const user = storedUserInfo ? JSON.parse(storedUserInfo) : null;

  const selectVar = (e: any, prodId: number) => {
    const vars = prods
      .filter((item: { id: any }) => item.id === prodId) // Get the item with the correct id
      .map((item: { variantProducts: any }) => item.variantProducts) // Extract the vars array from the filtered item
      .flat(); // Flatten the nested array to make it a single array

    const selectedPrice = parseFloat(e.target.value);
    setSelectedPrices((prev) => ({
      ...prev,
      [prodId]: selectedPrice,
    }));
    let selVar = vars
      .filter((v: { price: number }) => v.price == selectedPrice)
      .map(
        (v: {
          disponibilite: any;
          id: any;
          variant: { label: any };
          price: any;
        }) => ({
          id: v.id,
          label: v.variant.label,
          price: v.price,
          disponibilite: v.disponibilite,
        })
      );
    // vars
    //   .filter((variant: { id: number }) => variant.id == e.target.value)
    //   .map((v: { id: any; variant: { label: any }; price: any }) => ({
    //     id: v.id,
    //     label: v.variant.label,
    //     price: v.price,
    //   }));

    setCart((prevCart: any[]) => {
      const updatedCart = prevCart.map((item) => {
        if (item.id === prodId) {
          return {
            ...item,

            vars: selVar,
          };
        }
        return item;
      });

      return updatedCart;
    });
  };

  async function validateOrder() {
    if (cart.length === 0) {
      alert(t("Cart.emptyCart"));
      return;
    }
    if (user) {
      alert(t("Cart.alertShippingAddress"));
      const shippingInfo = document.getElementById("shippingInfo");
      if (shippingInfo) {
        shippingInfo.style.display = "block";
      }
    } else {
      if (window.confirm(t("Cart.confirmOffline"))) {
        const shippingInfo = document.getElementById("shippingInfo");
        if (shippingInfo) {
          shippingInfo.style.display = "block";
        }
      } else {
        alert(t("Cart.alertOnlineCmd"));
        navigate("/login");
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      prenom: user?.firstname || "",
      nom: user?.lastname || "",
      email: user?.email || "",
      telephone: user?.phoneNumber || "",
      pays: "",
      region: "",
      codepostal: "",
      rueInfo: "",
      ville:"",
      orderNote:""
    },
    validate: (values) => {
      const errors: { [key: string]: string } = {};
      if (!values.prenom) errors.prenom = t("errors.prenom");
      if (!values.nom) errors.nom = t("errors.nom");
      if (!values.email) errors.email = t("errors.email");
      if (!values.telephone) errors.telephone = t("errors.phone");
      if (!values.pays) errors.pays = t("errors.country");
      if (!values.region) errors.region = t("errors.region");
      if (!values.codepostal) errors.codepostal = t("errors.zipCode");
      if (!values.rueInfo) errors.rueInfo = t("errors.streetAddress");
      return errors;
    },
    onSubmit: async (values) => {
      const ref = Math.floor(Math.random() * 9000000) + 1000000;

      if (user) {
        const orderItems = cart.map((item: {
          id: number;
          quantity: number;
          vars: {
            disponibilite: any;
            label: any;
            price: any;
            id: number;
          }[];
        }) => {
          const produit = pr.find((p: { id: number }) => p.id === item.id);
          if(item.vars.length > 0){
            return {
              quantity: item.quantity,
              orderNote: values.orderNote,
              price : item.vars[0].price,
              variantProduct:"/api/variant_products/"+item.vars[0].id
      
          }
          }else{
            return {
              product : "/api/products/"+produit.id,
              quantity: item.quantity,
              orderNote:values.orderNote,
              price : produit.price
            }
          }
      
          
        });
     
        const totalQuantity = orderItems.reduce(
          (sum: number, item: { quantity: number }) => {
            return sum + item.quantity;
          },
          0
        );
        const orderDetails = {
          user: `/api/users/${user.id}`,
          reference: ref.toString(),
          totalPrice: total,
          orderStatue: "pending",
          paymentStatue: "pending",
          paymentMethod: "en ligne",
          orderItems,
          livraisonAdresse: {
            firstName: values.prenom,
            lastName: values.nom,
            email: values.email,
            telephone: values.telephone,
            pays: values.pays,
            region: values.region,
            codepostal: values.codepostal,
            rueInfo: values.rueInfo,
            adresseType: "string",
            ville: values.ville,
          },
          createdAt:
            new Date().toISOString().replace("T", " ").slice(0, 19) + "Z",
          itemsQuantity: totalQuantity,
        };
        
        try {
          const response = await axios.post(
            "https://api.faragroupe.fr/api/orders",
            JSON.stringify(orderDetails),
            { headers: { "Content-Type": "application/json" } }
          );
          setCart([]);
          alert("Order placed successfully!");
          navigate("/produits");
        } catch (error) {
          console.error("Order submission failed", error);
          alert("Error placing the order");
        }
      } else {
        
        const orderOfflineItem = cart.map((item: {
          id: number;
          quantity: number;
          vars: {
            
            price: any;
            id: number;
          }[];
        }) => {
          const produit = pr.find((p: { id: number }) => p.id === item.id);
          if(item.vars.length > 0){
            return {
              quantity: item.quantity,
              itemtype: "offline",
              price : item.vars[0].price,
              isvariant : true,
              variantProduct:"/api/variant_products/"+item.vars[0].id
      
          }
          }else{
            return {
              quantity: item.quantity,
              price : produit.price,
              itemtype: "offline",
              isvariant : false,
              product : "/api/products/"+produit.id,
            }
          }
      
          
        });
        const totalQuantity = orderOfflineItem.reduce(
          (sum: number, item: { quantity: number }) => {
            return sum + item.quantity;
          },
          0
        );
        const orderDetails = {
          reference: ref.toString(),
          fullName: `${values.prenom} ${values.nom}`,
          email: values.email,
          phoneNumber: values.telephone,
          adresse: values.rueInfo,
          ville: values.ville,
          codepostal: values.codepostal,
          totalPrice: total,
          itemsQuantity: totalQuantity,
          orderNote: values.orderNote,
          orderStatus: "pending",
          paymentMethod: "en ligne",
          paymentStatus: "pending",
          createdAt:
            new Date().toISOString().replace("T", " ").slice(0, 19) + "Z",
            updatedAt:
            new Date().toISOString().replace("T", " ").slice(0, 19) + "Z",
            orderOfflineItem: orderOfflineItem
        };

        try {
          const response = await axios.post(
            "https://api.faragroupe.fr/api/order_offlines",
            JSON.stringify(orderDetails),
            { headers: { "Content-Type": "application/json" } }
          );
          setCart([]);
          alert("Offline order placed successfully!");
          navigate("/produits");
        } catch (error) {
          console.error("Offline order submission failed", error);
          alert("Error placing the offline order");
        }
      }
    },
  });

  return (
    <>
      <DynamicNav />

      <section className="h-100 ">
        <div className="container mt-3">
          <div className="row">
            <CartTable
              cart={cart}
              prods={prods}
              selectedPrices={selectedPrices}
              handleQuantityChange={handleQuantityChange}
              selectVar={selectVar}
            />
            <CartSummary
              total={total}
              validateOrder={validateOrder}
              shippingCost={20}
            />
          </div>

          <div
            id="shippingInfo"
            className="row  mt-2 ml-2"
            style={{ display: "none" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <ShippingInfo formik={formik} />
              {/* <div
              className="card shadow-2-strong mb-5 mb-lg-0"
              style={{ borderRadius: "16px" }}
            >
              <div className="card-header">
                <h5 className="mb-0">Mode de payement</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6  mb-4 mb-md-0">
                    <form>
                      <div
                        className={`d-flex flex-row pb-3 ${selectedOption === "credit" ? "selected" : ""}`}
                        onClick={() => handleOptionChange("credit")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="rounded border w-100 p-3"
                        >
                          <p className="d-flex align-items-center mb-0">
                            <i className="fab fa-cc-mastercard fa-2x text-body pe-2 mr-2"></i>
                            {t("credit")}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`d-flex flex-row pb-3 ${selectedOption === "debit" ? "selected" : ""}`}
                        onClick={() => handleOptionChange("debit")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="rounded border w-100 p-3"
                        >
                          <p className="d-flex align-items-center mb-0">
                            <i className="fab fa-cc-visa fa-2x fa-lg text-body pe-2 mr-2"></i>
                            {t("debit")}
                          </p>
                        </div>
                      </div>
                      <div
                        className={`d-flex flex-row ${selectedOption === "paypal" ? "selected" : ""}`}
                        onClick={() => handleOptionChange("paypal")}
                      >
                        <div
                          style={{ cursor: "pointer" }}
                          className="rounded border w-100 p-3"
                        >
                          <p className="d-flex align-items-center mb-0">
                            <i className="fab fa-cc-paypal fa-2x fa-lg text-body pe-2 mr-2"></i>
                            PayPal
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>

                  
                  <div className="col-md-6 col-lg-4 col-xl-6" id="credit">
                    <div className="row">
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeName"
                            className="form-control form-control-lg"
                            placeholder="John Smith"
                          />
                          <label className="form-label" htmlFor="typeName">
                            {t("name")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeExp"
                            className="form-control form-control-lg"
                            placeholder="MM/YY"
                            size={7}
                          />
                          <label className="form-label" htmlFor="typeExp">
                            {t("expiry")}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeText"
                            className="form-control form-control-lg"
                            placeholder="1111 2222 3333 4444"
                            size={17}
                          />
                          <label className="form-label" htmlFor="typeText">
                            {t("number")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="password"
                            id="typeCvv"
                            className="form-control form-control-lg"
                            placeholder="●●●"
                            size={3}
                          />
                          <label className="form-label" htmlFor="typeCvv">
                            CVV
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  <div
                    id="debit"
                    className="col-md-6 col-lg-4 col-xl-6"
                    style={{ display: "none" }}
                  >
                    <div className="row">
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeName"
                            className="form-control form-control-lg"
                            placeholder="John Smith"
                          />
                          <label className="form-label" htmlFor="typeName">
                            {t("name")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeExp"
                            className="form-control form-control-lg"
                            placeholder="MM/YY"
                            size={7}
                          />
                          <label className="form-label" htmlFor="typeExp">
                            {t("expiry")}
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-xl-6">
                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="text"
                            id="typeText"
                            className="form-control form-control-lg"
                            placeholder="1111 2222 3333 4444"
                            size={17}
                          />
                          <label className="form-label" htmlFor="typeText">
                            {t("number")}
                          </label>
                        </div>

                        <div className="form-outline mb-4 mb-xl-5">
                          <input
                            type="password"
                            id="typeCvv"
                            className="form-control form-control-lg"
                            placeholder="●●●"
                            size={3}
                          />
                          <label className="form-label" htmlFor="typeCvv">
                            CVV
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  
                  <div
                    id="paypal"
                    className="col-md-6 col-lg-4 col-xl-6"
                    style={{ display: "none" }}
                  >
                    <div className="form-outline mb-4 mb-xl-5">
                      <input
                        type="email"
                        id="paypalEmail"
                        className="form-control form-control-lg"
                        placeholder="email@example.com"
                      />
                      <label className="form-label" htmlFor="paypalEmail">
                        Email PayPal
                      </label>
                    </div>
                    <p>{t("paypalPay")}</p>
                  </div>
                </div>
              </div>
             
            </div> */}
              <div className="col-12 text-right m-2">
                <button type="submit" className="btn btn-primary btn-md px-5">
                  Checkout
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default ShoppingCart;
