import React from "react";
import "../../styles/Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <img
        src="/loader.png"
        alt="Loading"
        className="rotating-logo"
      />
    </div>
  );
};

export default Loader;
