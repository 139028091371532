import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface NavOptions {
  name?: string;
}

const DynamicNav = (NavOptions: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();
  const currentPath = location.pathname;
  return (
    <nav className="d-flex ml-4 mt-1">
      <Link to="/">
        <i className="mr-1 fa-solid fa-house"></i>
      </Link>

      {currentPath === "/produits" && (
        <>
          <span className="mr-2">/</span>
          <Link to="/produits">{t("navbar.products")}</Link>
        </>
      )}

      {currentPath === `/details/${id}` && (
        <>
          <span className="mr-2">/</span>
          <Link to="/produits">{t("navbar.products")}</Link>
          <span className="mr-2 ml-2">/</span>
          <Link to={`/details/${id}`}>{NavOptions.name}</Link>
        </>
      )}

      {currentPath === "/apropos" && (
        <>
          <span className="mr-1 ml-1">/</span>
          <Link to={`/cart`}>{t("navbar.about")}</Link>
        </>
      )}
      {currentPath === "/cart" && (
        <>
        <span className="mr-2">/</span>
          <Link to="/produits">{t("navbar.products")}</Link>
          <span className="mr-1 ml-1">/</span>
          <Link to={`/cart`}>{t("navbar.cart")}</Link>
        </>
      )}

      {currentPath === `/profile/${id}` && (
        <>
          <span className="mr-2">/</span>
          <Link to=".">Profile</Link>
        </>
      )}
       {currentPath === `/like/${id}` && (
        <>
        <span className="mr-2">/</span>
          <Link to="/produits">{t("navbar.products")}</Link>
          <span className="mr-2 ml-2">/</span>
          
          <Link to=".">{t("wishlist.title")}</Link>
        </>
      )}
    </nav>
  );
};

export default DynamicNav;
