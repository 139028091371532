import React, { useState, useEffect, useRef } from "react";
import "../styles/Slider.css";
import { on } from "events";
import { Link } from "react-router-dom";

interface SliderProps {
  items: any[];
}

const Slider: React.FC<SliderProps> = ({ items }) => {
  return (
    <div className="d-flex align-items-center justify-content-center">
      {[...items].map((item, index) => (
        <Link to={`/details/${item.id}`}>
          <div className="text-center" key={index}>
            <img
              src={`https://api.faragroupe.fr/uploads/product_images/${item.picture}`}
              alt={`Product ${index}`}
              className=" mr-4 rounded"
              style={{width: "200px",
                height: "100%"
                }}
            />
            <h6>{item.title}</h6>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Slider;
