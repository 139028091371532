import React, { useContext, useEffect, useState } from "react";
import Footer from "../Components/Layout/Footer";
import { Carousel, initMDB } from "mdb-ui-kit";
import "../utils/script";
import { useTranslation } from "react-i18next";
import { GlobalContext } from "../App";
import "../styles/carroussel.css";
import Slider from "./Slider";



interface HomeProps {
  slider: any[];
}

const Home: React.FC<HomeProps> = ({ slider }) => {
   const { pr } = useContext(GlobalContext);
   
   useEffect(() => {
    initMDB({ Carousel });
  }, []);
  const { t } = useTranslation();
  useEffect(() => {
    const cards = document.querySelectorAll(".card-hidden");

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("card-visible");
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    cards.forEach((card) => observer.observe(card));
  }, []);

  return (
    <>
      <div
        id="carouselExample"
        className="carousel slide carousel-fade"
        data-ride="carousel"
      >
      

        <div className="carousel-inner">
          {slider.map((item, index) => (
            <div
              className={`carousel-item ${index === 0 ? "active" : ""} text-center`}
              key={index}
            >
              <img
                className="d-inline-block img-fluid custom-img"
                src={`https://api.faragroupe.fr/uploads/slider_images/${item.picture}`}
              />
              <div
                className="carousel-caption rounded-lg p-3"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.7)",
                  color: "black",
                }}
              >
                <h5 className="font-weight-bold">
                  {t("Home.caroussel." + index + ".title")}
                </h5>
                <p className="d-none d-md-block">
                  {t("Home.caroussel." + index + ".description")}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Previous Button */}
        <a
          className="carousel-control-prev"
          href="#carouselExample" // Matches the carousel's ID
          role="button"
          data-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Précédent</span>
        </a>

        {/* Next Button */}
        <a
          className="carousel-control-next"
          href="#carouselExample" // Matches the carousel's ID
          role="button"
          data-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="sr-only">Suivant</span>
        </a>
      </div>


      {/* <div className="container mb-3 mt-5">
        <div className="row mb-4 w3-animate-zoom align-items-center card-hidden">
          <div className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0">
            <img src="favicon.ico" className="img-fluid card-img" alt="Icône" />
          </div>
          <div className="col-md-8 text-center text-md-left">
            <h4 className="font-weight-bold">{t("Home.cards.0.title")}</h4>
            <p className="mb-0">{t("Home.cards.0.description")}</p>
          </div>
        </div>
        <hr />
        <div className="row mb-4 w3-animate-zoom align-items-center card-hidden">
          <div className="col-md-8 order-2 order-md-1 text-center text-md-left">
            <h4 className="font-weight-bold">{t("Home.cards.1.title")}</h4>
            <p className="mb-0">{t("Home.cards.1.description")}</p>
          </div>
          <div className="col-md-4 order-1 order-md-2 d-flex justify-content-center align-items-center mb-3 mb-md-0">
            <img
              src="https://www.pngmart.com/files/21/100-Natural-PNG-Photo.png"
              className="img-fluid card-img"
              alt="Ingrédients Organiques"
            />
          </div>
        </div>
        <hr />
        <div className="row w3-animate-zoom align-items-center card-hidden">
          <div className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0">
            <img
              src="https://images.squarespace-cdn.com/content/v1/5b69b60b2714e5c8dbc5c0a7/c038ebe2-8c10-48a6-9cd5-7922cdec3d4d/Heritage-logo-final-print.png"
              className="img-fluid card-img"
              alt="Héritage Marocain"
            />
          </div>
          <div className="col-md-8 text-center text-md-left">
            <h4 className="font-weight-bold">{t("Home.cards.2.title")}</h4>
            <p className="mb-0">{t("Home.cards.2.description")}</p>
          </div>
        </div>
      </div> */}
      <div className="mt-5 mb-4 pt-5 text-center text-dark" style={{fontSize:"32px"}}><strong>NOUVEAUTES</strong></div>
      <Slider items={pr} />
      <div className="mt-5 mb-4 pt-5 text-center text-dark" style={{fontSize:"32px"}}><strong>MEILLEURES VENTES</strong></div>
      {/* <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators">
    {pr.map((prod:any, index:any) => (
      <li
        key={index}
        data-target="#carouselExampleIndicators"
        data-slide-to={index}
        className={index === 0 ? "active" : ""}
      ></li>
    ))}
  </ol>
  <div className="carousel-inner">
    {pr.map((prod: any, index: number) => (
      <div
        className={`carousel-item ${index === 0 ? "active" : ""}`}
        key={pr.id}
      >
        <img
          src={`https://api.faragroupe.fr/uploads/product_images/${prod.picture}`}
          className="d-block w-100"
          alt="Product"
        />
      </div>
    ))}
  </div>
  <a
    className="carousel-control-prev"
    href="#carouselExampleIndicators"
    role="button"
    data-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a
    className="carousel-control-next"
    href="#carouselExampleIndicators"
    role="button"
    data-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div> */}


      <Footer />
    </>
  );
};

export default Home;
