import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../styles/App.css";
import { compressAndAppendImage } from '@mbs-dev/react-image-compressor'
// import "./registerCss.css"
import { RotatingLines } from "react-loader-spinner";
import Footer from "../Components/Layout/Footer";
import { t } from "i18next";
import { useFormik } from "formik";
import axios from "axios";
import { apiUrl } from "../process";

const Register: React.FC = () => {
  const history = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      fullName: '',
      picture: ''
    },
    onSubmit: async () => {
      setLoading(true);
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        if (formik.values.password !== formik.values.confirmPassword) {
          alert("Confirmation du mot de passe erronée");
          setLoading(false);
          return;
        }
        
  

        const formData = new FormData();
        formData.append("lastname", formik.values.lastname);
        formData.append("firstname", formik.values.firstname);
        formData.append("email", formik.values.email);

        formData.append("phoneNumber", formik.values.phoneNumber);
        formData.append("password", formik.values.password);
        
        try {
          const response = await axios.post(`${apiUrl}/register-client`, formData);
      
          if (response.status === 200) {
            alert('Utilisateur enregistré avec succès')
          } 
          else {
            alert('Erreur lors de l\'enregistrement de l\'utilisateur')
          }
        } catch (error) {
          alert('Erreur lors de l\'enregistrement de l\'utilisateur')
        }
      } catch (error) {
        console.error("Login failed", error);
      } finally {
        setLoading(false);
      }
    },
  });

  const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="video-wrapper">
        <video autoPlay muted loop className="video-background">
          <source
            src="https://videos.pexels.com/video-files/3127017/3127017-uhd_3840_2160_24fps.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <section className="form-section">
        <div className="container py-5">
          <div className="row justify-content-left align-items-center">
            <div className="col-12 col-lg-9 col-xl-7">
              <div
                className="card shadow-2-strong card-registration"
                style={{
                  borderRadius: "15px",
                  backgroundColor: "rgba(245, 246, 247, 0.6)",
                  color: "black",
                }}
              >
                <div className="card-body tex-dark p-4 p-md-5">
                  <h3
                    className="fw-normal mb-3 pb-3"
                    style={{ letterSpacing: "1px" }}
                  >
                    {t("register.title")}
                  </h3>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div
                        data-mdb-input-init
                        className="form-outline mb-2 col-6"
                      >
                        <input
                          required
                          type="text"
                          name="lastname"
                          value={formik.values.lastname}
                          id="form3Example2"
                          placeholder={t("register.lastName")}
                          onChange={formik.handleChange}
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" htmlFor="form3Example2">
                          {t("register.lastName")}
                        </label>
                      </div>
                      <div
                        data-mdb-input-init
                        className="form-outline mb-2 col-6"
                      >
                        <input
                          required
                          type="text"
                          name="firstname" // Add name attribute
                          value={formik.values.firstname}
                          id="form3Example1"
                          placeholder={t("register.firstName")}
                          onChange={formik.handleChange}
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" htmlFor="form3Example1">
                          {t("register.firstName")}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div data-mdb-input-init className="form-outline col-6">
                        <input
                          required
                          type="text"
                          name="phoneNumber"
                          pattern="[0-9]{10}"
                          value={formik.values.phoneNumber}
                          id="form3Example4"
                          placeholder="+212 XXX XXX XXX "
                          onChange={formik.handleChange}
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" htmlFor="form3Example4">
                          {t("register.phone")}
                        </label>
                      </div>
                      <div
                        data-mdb-input-init
                        className="form-outline mb-2 col-6"
                      >
                        <input
                          type="email"
                          name="email"
                          placeholder="example@hotmail.com"
                          id="form3Example3"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" htmlFor="form3Example3">
                          {t("register.email")}
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        data-mdb-input-init
                        className="form-outline mb-2 col-6"
                      >
                        <input
                          required
                          type="password"
                          name="password" // Add name attribute
                          value={formik.values.password}
                          id="form3Example1"
                          placeholder="●●●●●●●●●●"
                          onChange={formik.handleChange}
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" htmlFor="form3Example1">
                          {t("register.password")}
                        </label>
                      </div>
                      <div
                        data-mdb-input-init
                        className="form-outline mb-2 col-6"
                      >
                        <input
                          required
                          type="password"
                          name="confirmPassword" 
                          placeholder="●●●●●●●●●●"
                          value={formik.values.confirmPassword}
                          id="form3Example2"
                          onChange={formik.handleChange}
                          className="form-control form-control-lg"
                        />
                        <label className="form-label" htmlFor="form3Example2">
                          {t("register.confirmPassword")}
                        </label>
                      </div>
                     
                      
                    </div>
                    <div className="pt-1 mb-2">
                      <button
                        data-mdb-button-init
                        data-mdb-ripple-init
                        className="btn btn-dark btn-lg btn-block"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? (
                          <RotatingLines
                            width="24"
                            strokeColor="white"
                            strokeWidth="5"
                            animationDuration="0.75"
                          />
                        ) : (
                          t("register.register")
                        )}
                      </button>
                    </div>
                    <p>
                      {t("register.alreadyAccount")}{" "}
                      <Link to="/login" className="link-info text-primary">
                        {t("login.login")}
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Register;
